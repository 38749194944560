import {autoinject, computedFrom, observable} from 'aurelia-framework';
import {syndicationScheduleActions, SyndicationScheduleService} from 'services/syndication-schedule-service';
import {LiveEventsService} from 'apps/cms/routes/live-events/live-events/services/live-events';
import {LiveChannelsService} from 'apps/cms/routes/live-channels/channels/services/live-channels';
import {SyndicationTargetService} from 'services/syndication-target-service';
import {SessionService} from 'services/session';
import {Toast} from 'resources/toast/toast';
import {Router} from 'aurelia-router';
import {
  SYNDICATION_SCTE35_ALLOWED,
  SyndicationSchedule,
  SyndicationHlsUriType,
  SyndicationPlatformType,
  SyndicationRegion,
  SyndicationScheduleVideoQualityType,
  SyndicationScte35Types,
  SyndicationTargetProtocolType,
  SyndicationYouTubeIntegratedPrivacy,
} from 'services/models/syndication';

@autoinject()
export class SyndicationJobsSingle {
  public isLoading: boolean = false;
  public isDeleting: boolean = false;
  public isError: boolean = false;
  public jobID: string;
  public job: any;
  public cleanJob: any;
  public source: any;
  public target: any;
  public regions: SyndicationRegion[] = [];
  public showTargetPrepareError: boolean = false;
  public jobPullInterval: any;

  @observable
  public ytMadeForKidsRadio: string;

  private SCHEDULE_PULL_INTERVAL_MS = 10000;
  private SAFE_TO_DELETE: string[] = [
    'stopped',
    'error',
    'scheduled',
  ];

  constructor(
    public syndicationScheduleService: SyndicationScheduleService,
    public syndicationTargetService: SyndicationTargetService,
    public liveChannelsService: LiveChannelsService,
    public liveEventsService: LiveEventsService,
    public session: SessionService,
    public router: Router,
  ) {}

  public activate(params) {
    this.jobID = params.id;
    this.loadData();
  }

  public async loadData() {
    try {
      this.isLoading = true;
      await this.syndicationScheduleService.getSyndicationScheduleSingle(this.jobID);

      this.job = _.cloneDeep(this.syndicationScheduleService.currentSchedule);

      await this.loadSourceData();
      await this.loadTargetData();
      await this.loadJobDetails();

      this.cleanJob = _.cloneDeep(this.job);
      // console.log(this.job, this.target, this.source);
    } catch (e) {
      this.isError = true;
    } finally {
      this.isLoading = false;

      if (this.job) {
        this.pollJob();
      }
    }
  }

  public async refreshJobData() {
    await this.syndicationScheduleService.getSyndicationScheduleSingle(this.jobID);
    this.job = _.cloneDeep(this.syndicationScheduleService.currentSchedule);
    await this.loadJobDetails();
    this.cleanJob = _.cloneDeep(this.job);
  }

  public async loadSourceData() {
    if (this.job.content_type === 'e') {
      await this.liveEventsService.getLiveEvent(this.job.content_id);
      this.source = this.liveEventsService.model;
    } else {
      await this.liveChannelsService.getSingleChannel(this.job.content_id);
      this.source = this.liveChannelsService.currentModel;
    }
  }

  public async loadTargetData() {
    await this.syndicationTargetService.getSyndicationTarget(this.job.syndication_target_id);
    this.target = this.syndicationTargetService.currentModel;

    const syndRegions: SyndicationRegion[] = await this.syndicationScheduleService.getSyndicationRegions(
      this.target.target_protocol,
    );

    this.regions = syndRegions;
  }

  public async loadJobDetails() {
    const rayDetail = await this.syndicationScheduleService.getSyndicationScheduleRay(this.job.id);

    let defaultRays = [];

    if (_.isObject(rayDetail) && Object.prototype.hasOwnProperty.call(rayDetail, 'all') && rayDetail.all.length) {
      defaultRays = rayDetail.all.map(ray => ray.ray_name);
    }

    this.job.rayDetail = rayDetail;
    this.job.audio_track = '';
    this.job.canUseSCTE = SYNDICATION_SCTE35_ALLOWED.includes(this.target.target_protocol);
    this.job.hls_uri_type =
      this.target.target_protocol === SyndicationTargetProtocolType.HLS_PUSH
        ? this.target.platform_config.hls_uri_type
        : SyndicationHlsUriType.NONE;
    this.job.target_protocol = this.target.target_protocol;

    if (!this.job.target_platform_config.rays?.length) {
      this.job.target_platform_config.rays = _.cloneDeep(defaultRays);
    }

    let ytMadeForKids = false;
    if ('target_platform_config' in this.job && 'integrated_yt_made_for_kids' in this.job.target_platform_config) {
      ytMadeForKids = this.job.target_platform_config.integrated_yt_made_for_kids;
    }
    this.ytMadeForKidsRadio = `yt_made_for_kids_${ytMadeForKids}`;

    this.showTargetPrepareError =
      this.target &&
      this.job.status === 'error' &&
      (this.target.platform === SyndicationPlatformType.YOUTUBE ||
        this.target.platform === SyndicationPlatformType.FACEBOOK ||
        this.target.target_protocol === SyndicationTargetProtocolType.ZIXI ||
        this.target.target_protocol === SyndicationTargetProtocolType.RTMP_PULL);
  }

  public onRayCheckedChange(ray, isChecked) {
    ray.value = isChecked;

    const rays = _.cloneDeep(this.job.target_platform_config.rays);
    if (rays.includes(ray.id)) {
      const idx = rays.indexOf(ray.id);
      if (idx !== -1) {
        rays.splice(idx, 1);
      }
    } else {
      rays.push(ray.id);
    }
    rays.sort();
    this.job.target_platform_config.rays = _.cloneDeep(rays);
  }

  public rayVideoDescription(ray) {
    let value = '';
    if (ray !== undefined) {
      if (ray.video_kbps >= 1000) {
        const speed = (ray.video_kbps / 1000).toFixed(2);
        value = `${ray.video_codec} Video @ ${speed}Mbps`;
      } else {
        value = `${ray.video_codec} Video @ ${ray.video_kbps}Kbps`;
      }
    }
    return value;
  }

  public rayAudioDescription(ray) {
    return `${ray.audio_codec} Audio @ ${ray.audio_kbps}Kbps`;
  }

  public rayResolutionDescription(ray) {
    return `${ray.max_width}x${ray.max_height}`;
  }

  public rayFPSDescription(ray) {
    return `${ray.fps}`;
  }

  public ytMadeForKidsRadioChanged(newVal: string) {
    if ('target_platform_config' in this.job && 'integrated_yt_made_for_kids' in this.job.target_platform_config) {
      this.job.target_platform_config.integrated_yt_made_for_kids = newVal.split('_').pop() === 'true';
    }
  }

  public onSrtLatencyBlur() {
    let srtLatency: any = `${this.job.target_platform_config.srt_latency}`;
    srtLatency = srtLatency.replace(/\D/g, '');
    srtLatency = parseInt(srtLatency, 10);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(srtLatency) || srtLatency < 120 || srtLatency > 15000) {
      srtLatency = 500;
    }
    this.job.target_platform_config.srt_latency = `${srtLatency}`;
  }

  public dictsEqual(dict1, dict2) {
    if (JSON.stringify(dict1) === JSON.stringify(dict2)) {
      return true;
    }
    return false;
  }

  public async saveJob() {
    const data = new SyndicationSchedule();
    const {job} = this;

    data.video_quality = job.video_quality;
    data.id = job.id;
    data.content_id = job.content_id;
    data.content_type = job.content_type;

    if (job.canUseSCTE) {
      data.scte35_enabled = job.scte35_enabled ? 1 : 0;
    }

    data.region = job.region;
    data.region_auto_selected = job.region === '';
    data.target_platform_config = job.target_platform_config;
    data.source_url_params = job.source_url_params;
    data.url_params_active = job.url_params_active;

    delete data.syndication_target_id;
    delete data.err_msg;
    delete data.source_stream;
    delete data.status;
    delete data.type;
    delete data.is_new;

    try {
      const ans = await this.syndicationScheduleService.saveSyndicationSchedule(data);
      job.status = ans.status;
      this.cleanJob = _.cloneDeep(this.job);

      // this.refreshJobData();
      Toast.success('Job Saved');
      return true;
    } catch (e) {
      Toast.danger(e.message);
      return false;
    }
  }

  public async deleteJob() {
    const {job} = this;
    this.isDeleting = true;

    if (job.status === 'scheduled') {
      Toast.warning(`Unable to delete a scheduled job. Please stop it first and try again.`);
    }
    if (!this.SAFE_TO_DELETE.includes(job.status)) {
      Toast.warning(`Unable to delete ${job.status} schedule.  Schedule must be fully stopped before delete.`);
    }
    if (this.target.platform === SyndicationPlatformType.WURL && !this.session.hasFastAccess) {
      Toast.warning('This job cannot be deleted, please reach out to Support.');
    }
    if (job.id) {
      try {
        await this.syndicationScheduleService.deleteSchedule(job.id);
        Toast.info('Job deleted');
        this.router.navigate('/syndication/jobs');
      } catch (e) {
        Toast.danger(e.message);
      }
    }
    this.isDeleting = false;
  }

  public async startJob() {
    return this.jobAction(syndicationScheduleActions.START, 'Starting job...');
  }

  public async stopJob() {
    return this.jobAction(syndicationScheduleActions.STOP, 'Stopping job...');
  }

  public async resetJob() {
    return this.jobAction(syndicationScheduleActions.RESET_STATUS, 'Job has been reset');
  }

  public async jobAction(action: string, infoMsg: string) {
    try {
      await this.syndicationScheduleService.syndicationScheduleAction(this.job.id, action);
      Toast.warning(infoMsg);
      this.pollJob();
      return true;
    } catch (e) {
      Toast.danger(e.message);
      return false;
    }
  }

  public async pollJob() {
    try {
      await this.syndicationScheduleService.getSyndicationScheduleSingle(this.jobID);
      const updatedSchedule = _.cloneDeep(this.syndicationScheduleService.currentSchedule);

      this.job.status = updatedSchedule.status;
      this.job.err_msg = updatedSchedule.err_msg;
    } catch (e) {
      // Don't do anything on a failed poll
    }

    this.jobPullInterval = setTimeout(() => {
      this.pollJob();
    }, this.SCHEDULE_PULL_INTERVAL_MS);
  }

  @computedFrom('job.status')
  get statusBadgeType() {
    switch (this.job.status) {
      case 'active':
        return 'success';
      case 'error':
        return 'danger';
      case 'scheduled':
      case 'restarting':
      case 'stopping':
        return 'warning';
      default:
        return 'neutral';
    }
  }

  @computedFrom('job.status')
  get statusIcon() {
    switch (this.job.status) {
      case 'active':
        return 'play-fill';
      case 'error':
        return 'exclamation-lg';
      default:
        return 'arrow-right';
    }
  }

  @computedFrom('job.target_protocol')
  get isHls() {
    return (
      this.job.target_protocol === SyndicationTargetProtocolType.HLS_PUSH ||
      this.job.target_protocol === SyndicationTargetProtocolType.HLS_PULL
    );
  }

  @computedFrom('target.platform', 'target.platform_config.integrated')
  get isIntegrated() {
    return (
      this.target.platform_config.integrated &&
      (this.target.platform === 'YouTube' || this.target.platform === 'Facebook')
    );
  }

  @computedFrom('job.target_protocol')
  get isSrt() {
    return this.job.target_protocol === SyndicationTargetProtocolType.SRT;
  }

  @computedFrom('target.platform', 'session')
  get wurlWithoutAccess() {
    return this.target.platform === SyndicationPlatformType.WURL && !this.session.hasFastAccess;
  }

  @computedFrom('job.hls_uri_type')
  get showChannelIdInput() {
    return this.job.hls_uri_type === SyndicationHlsUriType.CHANNEL_ID;
  }

  @computedFrom('SyndicationYouTubeIntegratedPrivacy')
  get youTubeIntegratedPrivacyOptions() {
    return _.map(SyndicationYouTubeIntegratedPrivacy, info => ({
      text: info.charAt(0).toUpperCase() + info.slice(1),
      value: info,
    }));
  }

  @computedFrom('SyndicationScte35Types')
  get scte35Types() {
    const s35options = [
      {
        text: 'DATERANGE',
        value: SyndicationScte35Types.DATERANGE,
      },
      {
        text: 'CUE-OUT / IN',
        value: SyndicationScte35Types.CUE,
      },
    ];
    return s35options.filter(
      value => !(this.target.platform === SyndicationPlatformType.WURL && value.value !== SyndicationScte35Types.CUE),
    );
  }

  @computedFrom('SyndicationScheduleVideoQualityType')
  get qualityOptions() {
    return _.map(SyndicationScheduleVideoQualityType, info => ({
      text: info.charAt(0).toUpperCase() + info.slice(1),
      value: info,
    }));
  }

  @computedFrom('job.rayDetail')
  get rayDetailAvailable() {
    return this.job.rayDetail !== undefined;
  }

  @computedFrom('job.rayDetail', 'job.video_quality')
  get rayDescription() {
    let value = '';
    if (this.job.rayDetail !== undefined) {
      if (this.job.video_quality === SyndicationScheduleVideoQualityType.HIGH) {
        value = `<span>${this.job.rayDetail.high.max_width}x${this.job.rayDetail.high.max_height}</span> • <span>${this.job.rayDetail.high.fps}fps</span> • <span>${this.rayVideo}</span> • <span>${this.job.rayDetail.high.audio_codec} Audio @ ${this.job.rayDetail.high.audio_kbps}Kbps</span>`;
      } else if (this.job.video_quality === SyndicationScheduleVideoQualityType.MEDIUM) {
        value = `<span>${this.job.rayDetail.medium.max_width}x${this.job.rayDetail.medium.max_height}</span> • <span>${this.job.rayDetail.medium.fps}fps</span> • <span>${this.rayVideo}</span> • <span>${this.job.rayDetail.medium.audio_codec} Audio @ ${this.job.rayDetail.medium.audio_kbps}Kbps</span>`;
      } else if (this.job.video_quality === SyndicationScheduleVideoQualityType.LOW) {
        value = `<span>${this.job.rayDetail.low.max_width}x${this.job.rayDetail.low.max_height}</span> • <span>${this.job.rayDetail.low.fps}fps</span> • <span>${this.rayVideo}</span> • <span>${this.job.rayDetail.low.audio_codec} Audio @ ${this.job.rayDetail.low.audio_kbps}Kbps</span>`;
      }
    }
    return value;
  }

  @computedFrom('job.rayDetail', 'job.video_quality')
  get rayResolution() {
    let value = '';
    if (this.job.rayDetail !== undefined) {
      if (this.job.video_quality === SyndicationScheduleVideoQualityType.HIGH) {
        value = this.rayResolutionDescription(this.job.rayDetail.high);
      } else if (this.job.video_quality === SyndicationScheduleVideoQualityType.MEDIUM) {
        value = this.rayResolutionDescription(this.job.rayDetail.medium);
      } else if (this.job.video_quality === SyndicationScheduleVideoQualityType.LOW) {
        value = this.rayResolutionDescription(this.job.rayDetail.low);
      }
    }
    return value;
  }

  @computedFrom('job.rayDetail', 'job.video_quality')
  get rayFPS() {
    let value = '';
    if (this.job.rayDetail !== undefined) {
      if (this.job.video_quality === SyndicationScheduleVideoQualityType.HIGH) {
        value = this.rayFPSDescription(this.job.rayDetail.high);
      } else if (this.job.video_quality === SyndicationScheduleVideoQualityType.MEDIUM) {
        value = this.rayFPSDescription(this.job.rayDetail.medium);
      } else if (this.job.video_quality === SyndicationScheduleVideoQualityType.LOW) {
        value = this.rayFPSDescription(this.job.rayDetail.low);
      }
    }
    return value;
  }

  @computedFrom('job.rayDetail', 'job.video_quality')
  get rayVideo() {
    let value = '';
    if (this.job.rayDetail !== undefined) {
      if (this.job.video_quality === SyndicationScheduleVideoQualityType.HIGH) {
        value = this.rayVideoDescription(this.job.rayDetail.high);
      } else if (this.job.video_quality === SyndicationScheduleVideoQualityType.MEDIUM) {
        value = this.rayVideoDescription(this.job.rayDetail.medium);
      } else if (this.job.video_quality === SyndicationScheduleVideoQualityType.LOW) {
        value = this.rayVideoDescription(this.job.rayDetail.low);
      }
    }
    return value;
  }

  @computedFrom('job.rayDetail', 'job.video_quality')
  get rayAudio() {
    let value = '';
    if (this.job.rayDetail !== undefined) {
      if (this.job.video_quality === SyndicationScheduleVideoQualityType.HIGH) {
        value = this.rayAudioDescription(this.job.rayDetail.high);
      } else if (this.job.video_quality === SyndicationScheduleVideoQualityType.MEDIUM) {
        value = this.rayAudioDescription(this.job.rayDetail.medium);
      } else if (this.job.video_quality === SyndicationScheduleVideoQualityType.LOW) {
        value = this.rayAudioDescription(this.job.rayDetail.low);
      }
    }
    return value;
  }

  @computedFrom('job.target_protocol')
  get showAllVideos() {
    return (
      (this.job && this.job.target_protocol === SyndicationTargetProtocolType.HLS_PUSH) ||
      (this.job && this.job.target_protocol === SyndicationTargetProtocolType.HLS_PULL)
    );
  }

  @computedFrom('job.rayDetail')
  get getAllRays() {
    if (this.job.rayDetail === undefined) {
      return [];
    }
    return _.map(this.job.rayDetail.all, ray => ({
      id: ray.ray_name,
      label: `${this.rayResolutionDescription(ray)} • ${this.rayFPSDescription(ray)}fps •
                ${this.rayVideoDescription(ray)} • ${this.rayAudioDescription(ray)}`,
      value:
        this.job.target_platform_config.rays.length === 0 ||
        this.job.target_platform_config.rays.includes(ray.ray_name),
    }));
  }

  @computedFrom(
    'job.video_quality',
    'cleanJob.video_quality',
    'job.scte35_enabled',
    'cleanJob.scte35_enabled',
    'job.region',
    'cleanJob.region',
    'job.target_platform_config.channel_id',
    'cleanJob.target_platform_config.channel_id',
    'job.target_platform_config.rays',
    'cleanJob.target_platform_config.rays',
    'job.target_platform_config.integrated_title',
    'cleanJob.target_platform_config.integrated_title',
    'job.target_platform_config.integrated_description',
    'cleanJob.target_platform_config.integrated_description',
    'job.target_platform_config.integrated_yt_privacy',
    'cleanJob.target_platform_config.integrated_yt_privacy',
    'job.target_platform_config.integrated_yt_made_for_kids',
    'cleanJob.target_platform_config.integrated_yt_made_for_kids',
    'job.target_platform_config.scte35_type',
    'cleanJob.target_platform_config.scte35_type',
    'job.target_platform_config.srt_latency',
    'cleanJob.target_platform_config.srt_latency',
    'job.target_platform_config.fast_channel_id',
    'cleanJob.target_platform_config.fast_channel_id',
    'job.source_url_params',
    'cleanJob.source_url_params',
    'job.url_params_active',
    'cleanJob.url_params_active',
  )
  get isDirty() {
    const {job, cleanJob} = this;

    if (
      // only for HLS, verify user selected at least one ray
      (this.isHls && job.target_platform_config.rays.length === 0) ||
      (this.isIntegrated && job.target_platform_config.integrated_title === '')
    ) {
      return false;
    }

    return (
      job.video_quality !== cleanJob.video_quality ||
      job.scte35_enabled !== cleanJob.scte35_enabled ||
      job.region !== cleanJob.region ||
      job.url_params_active !== cleanJob.url_params_active ||
      job.target_platform_config.channel_id !== cleanJob.target_platform_config.channel_id ||
      (job.target_platform_config.rays.length > 0 &&
        !_.isEqual(job.target_platform_config.rays, cleanJob.target_platform_config.rays)) ||
      job.target_platform_config.integrated_title !== cleanJob.target_platform_config.integrated_title ||
      // tslint:disable-next-line:max-line-length
      job.target_platform_config.integrated_description !== cleanJob.target_platform_config.integrated_description ||
      // tslint:disable-next-line:max-line-length
      job.target_platform_config.integrated_yt_privacy !== cleanJob.target_platform_config.integrated_yt_privacy ||
      // tslint:disable-next-line:max-line-length
      job.target_platform_config.integrated_yt_made_for_kids !==
        cleanJob.target_platform_config.integrated_yt_made_for_kids ||
      // tslint:disable-next-line:max-line-length
      job.target_platform_config.srt_latency !== cleanJob.target_platform_config.srt_latency ||
      !this.dictsEqual(job.source_url_params, cleanJob.source_url_params) ||
      job.target_platform_config.scte35_type !== cleanJob.target_platform_config.scte35_type ||
      job.target_platform_config.fast_channel_id !== cleanJob.target_platform_config.fast_channel_id
    );
  }

  @computedFrom('isSrt', 'isDirty', 'isHls', 'isIntegrated', 'job.target_platform_config.srt_latency')
  get saveButtonDisabled() {
    // for HLS we want to validate and require at least one ray selected,
    // so it must be "dirty" and not pass the if "is_new" part
    if (this.isHls && !this.isDirty) {
      return true;
    }
    // for YT/FB integrated we want the title to be not empty
    if (this.isIntegrated && !this.isDirty) {
      return true;
    }
    // for SRT validate srt latency is valid number and allowed range
    if (this.isSrt) {
      const srtLatency: any = this.job.target_platform_config.srt_latency;
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(srtLatency) || srtLatency < 120 || srtLatency > 15000) {
        return true;
      }
    }
    return !this.isDirty;
  }

  @computedFrom('job.isDirty', 'job.status')
  get disableStart() {
    return this.isDirty || this.job.status !== 'stopped' || this.wurlWithoutAccess;
  }

  @computedFrom('job.status')
  get disableStop() {
    return this.job.status !== 'active' || this.wurlWithoutAccess;
  }

  @computedFrom('target.platform', 'session')
  get disableClear(): boolean {
    return this.target.platform === SyndicationPlatformType.WURL && !this.session.hasFastAccess;
  }

  @computedFrom('job.content_type')
  get sourceHref() {
    if (this.job.content_type === 'c') {
      return `#/live-channels/channels/${this.job.content_id}/publish`;
    }
    return `#/live-events/events/${this.job.content_id}/publish`;
  }
}
