/* eslint-disable camelcase */
import {Toast} from 'resources/toast/toast';
import {autoinject, LogManager, singleton} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {SyndicationRegion, SyndicationRegionCollection} from './models/syndication';
import {JobsMeta, SyndicationJob, SyndicationJobCollection} from './models/syndication-job';
import {DEFAULT_PAGE_NUM, DEFAULT_PER_PAGE_LIMIT} from './models/defaults';
import {syndicationScheduleActions, SyndicationScheduleService} from './syndication-schedule-service';
import {SessionService} from './session';

export const SYNDICATION_JOBS_URL: string = '/api/v4/syndication-jobs';
export const SYNDICATION_REGION_URL: string = '/api/v4/syndication-regions';

const log = LogManager.getLogger('syndication-job-service');

@autoinject()
@singleton()
export class SyndicationJobService {
  public readonly patchErrorMessage = 'PATCH Error';
  public syndicationJobs: SyndicationJob[] = [];
  public meta: JobsMeta = {};
  public params: any = {};
  public syndicationRegions: SyndicationRegion[] = [];
  public searchQuery: string = '';
  public searchQueryApplied: string = '';

  constructor(
    public acceo: Acceo,
    public syndicationScheduleService: SyndicationScheduleService,
    public session: SessionService,
  ) {}

  public async startSchedule(id: string) {
    return this.scheduleAction(id, syndicationScheduleActions.START, 'Starting job...');
  }

  public async stopSchedule(id: string) {
    return this.scheduleAction(id, syndicationScheduleActions.STOP, 'Stopping job...');
  }

  public async resetSchedule(id: string) {
    return this.scheduleAction(id, syndicationScheduleActions.RESET_STATUS, 'Job has been reset');
  }

  private async scheduleAction(id: string, action: string, infoMsg: string) {
    try {
      await this.syndicationScheduleService.syndicationScheduleAction(id, action);
      Toast.info(infoMsg);
      this.getSyndicationJobsUpdates([id]);
      return true;
    } catch (e) {
      Toast.danger(e.message);
      return false;
    }
  }

  public getSyndicationJobs(contentType: string, statusTypeDic: any, overrideJobs: boolean): Promise<SyndicationJob[]> {
    const statusArr: string[] = [];
    if (statusTypeDic.statusType_active) {
      statusArr.push('active');
    }
    if (statusTypeDic.statusType_scheduled) {
      statusArr.push('scheduled');
    }
    if (statusTypeDic.statusType_stopped) {
      statusArr.push('stopped');
    }
    if (statusTypeDic.statusType_error) {
      statusArr.push('error');
    }
    let content_type: string = '';
    if (contentType === 'assetType_liveEvent') {
      content_type = 'e';
    } else if (contentType === 'assetType_channel') {
      content_type = 'c';
    }
    let search = '';
    if (this.searchQuery) {
      search = this.searchQuery;
    }
    const status = statusArr.toString();
    const {page} = this.params;
    const {page_size} = this.params;
    const url_params = $.param({content_type, status, page, page_size, search});
    return new Promise((resolve, reject) => {
      this.acceo
        .get(SyndicationJobCollection)(`${SYNDICATION_JOBS_URL}?${url_params}`)
        .then((res: SyndicationJobCollection) => {
          if (overrideJobs) {
            this.syndicationJobs = res.items;
          } else {
            this.syndicationJobs = this.syndicationJobs.concat(res.items);
          }
          this.meta.total = res.total_items;
          this.meta.showing = this.syndicationJobs.length;
          this.meta.limit = this.params.page_size || null;
          this.meta.hasMore = this.syndicationJobs.length < res.total_items;
          resolve(this.syndicationJobs);
        })
        .catch(er => {
          const msg = 'GET Error';
          log.error(`${msg}:`, er);
          reject(new Error(msg));
        });
    });
  }

  /**
   * Search the jobs
   *
   * @param searchQuery Optional search term that comes from the sidebar
   */
  public search(searchQuery?: string) {
    this.searchQuery = searchQuery;
  }

  public resetSearch() {
    this.searchQuery = '';
    this.searchQueryApplied = '';
  }

  public updateParams(params: any = null, isLoadMore: boolean = false) {
    if (!params.page) {
      params.page = DEFAULT_PAGE_NUM;
    }
    if (!params.page_size) {
      params.page_size = DEFAULT_PER_PAGE_LIMIT;
    }

    if (isLoadMore) {
      params.page =
        Math.floor((this.meta.total - (this.meta.total - this.syndicationJobs.length)) / params.page_size) + 1;
    }
    this.params = _.cloneDeep(params);
  }

  /**
   * Gets more jobs based on scrolling the table
   */
  public getMoreJobs() {
    if (this.meta.showing < this.meta.total) {
      const params = _.cloneDeep(this.params);
      this.updateParams(params, true);
    }
  }

  public getSyndicationJobsUpdates(ids: any) {
    return new Promise((resolve, reject) => {
      this.acceo
        .post(SyndicationJobCollection)(`${SYNDICATION_JOBS_URL}/updates`, {ids})
        .then((res: SyndicationJobCollection) => {
          const updatedJobs = res.items;
          _.each(updatedJobs, job => {
            const index = _.findIndex(this.syndicationJobs, {id: job.id});
            this.syndicationJobs[index] = job;
          });

          // check if some of the shown jobs deleted and update it
          const recieve_ids = updatedJobs.map(job => job.id);
          if (ids.length > recieve_ids.length) {
            const missing_ids = ids.filter(id => !recieve_ids.includes(id));
            if (missing_ids.length > 0) {
              _.each(missing_ids, id => {
                _.remove(this.syndicationJobs, {id});
                this.meta.total -= 1;
                this.meta.showing = this.syndicationJobs.length;
              });
            }
          }
          resolve(this.syndicationJobs);
        })
        .catch(er => {
          const msg = 'POST Error';
          log.error(`${msg}:`, er);
          reject(new Error(msg));
        });
    });
  }

  public getSyndicationRegions(): Promise<SyndicationRegion[]> {
    return new Promise((resolve, reject) => {
      this.acceo
        .get(SyndicationRegionCollection)(SYNDICATION_REGION_URL)
        .then((resp: SyndicationRegionCollection) => {
          this.syndicationRegions = resp.items;
          this.syndicationRegions.unshift({value: '', text: ''});
          resolve(this.syndicationRegions);
        })
        .catch(er => {
          let msg = 'Error getting Regions';
          if (er.message) {
            Toast.danger(er.message);
            msg = er.message;
          }
          log.error(`${msg}:`, er);
          reject(new Error(msg));
        });
    });
  }
}
