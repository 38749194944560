import {CToastsService} from '@bindable-ui/bindable';
import {autoinject, LogManager, singleton} from 'aurelia-framework';
import {classToPlain} from 'class-transformer';
import {Acceo} from 'services/acceo';
import {
  SyndicationRayDetail,
  SyndicationRegion,
  SyndicationRegionCollection,
  SyndicationSchedule,
  SyndicationScheduleCollection,
  SyndicationScheduleContentType,
} from './models/syndication';

export const SYNDICATION_RAY_URL: string = '/api/v4/syndication-ray-details/';
export const SYNDICATION_REGION_URL: string = '/api/v4/syndication-regions';
export const SYNDICATION_SCHEDULE_URL: string = '/api/v4/syndication-schedule/';

export enum syndicationScheduleActions {
  START = 'start',
  STOP = 'stop',
  RESET_STATUS = 'reset_status',
}

const log = LogManager.getLogger('syndication-schedule-service');
@autoinject()
@singleton()
export class SyndicationScheduleService {
  public readonly patchErrorMessage = 'PATCH Error';
  public syndicationSchedules: SyndicationSchedule[] = [];
  public currentSchedule: SyndicationSchedule;
  public syndicationRegions: SyndicationRegion[] = [];

  constructor(public acceo: Acceo, public notification: CToastsService) {}

  public getSyndicationSchedules(
    content_type: SyndicationScheduleContentType,
    content_id: string,
  ): Promise<SyndicationSchedule[]> {
    const params = $.param({content_type, content_id});
    return new Promise((resolve, reject) => {
      this.acceo
        .get(SyndicationScheduleCollection)(`${SYNDICATION_SCHEDULE_URL}?${params}`)
        .then((resp: SyndicationScheduleCollection) => {
          this.syndicationSchedules = resp.items;
          resolve(this.syndicationSchedules);
        })
        .catch(er => {
          const msg = 'GET Error';
          log.error(`${msg}:`, er);
          reject(new Error(msg));
        });
    });
  }

  public getSyndicationScheduleSingle(id: string): Promise<SyndicationSchedule> {
    return new Promise((resolve, reject) => {
      this.acceo
        .get(SyndicationSchedule)(`${SYNDICATION_SCHEDULE_URL}${id}/`)
        .then((resp: SyndicationSchedule) => {
          this.currentSchedule = resp;
          resolve(this.currentSchedule);
        })
        .catch(er => {
          const msg = 'GET Error';
          log.error(`${msg}:`, er);
          reject(new Error(msg));
        });
    });
  }

  public async saveSyndicationSchedule(syndicationTarget: SyndicationSchedule): Promise<SyndicationSchedule> {
    const data: any = classToPlain(syndicationTarget);
    const {id} = syndicationTarget;
    const errorMsg = `Error ${id ? 'sav' : 'creat'}ing Syndication Target. Please try again.`;
    delete data.id;
    delete data.status;
    return new Promise((resolve, reject) => {
      if (id) {
        this.acceo
          .patch(SyndicationSchedule)(`${SYNDICATION_SCHEDULE_URL}${id}/`, data)
          .then((resp: SyndicationSchedule) => {
            this.syndicationSchedules = this.syndicationSchedules.map(schedule =>
              schedule.id === id ? resp : schedule,
            );
            resolve(resp);
          })
          .catch(e => {
            this.displayErrorInformation(e);
            log.error(errorMsg);
            reject(new Error(errorMsg));
          });
      } else {
        this.acceo
          .post(SyndicationSchedule)(SYNDICATION_SCHEDULE_URL, data)
          .then((resp: SyndicationSchedule) => {
            this.syndicationSchedules = _.uniqBy(this.syndicationSchedules.concat(resp), 'id');
            resolve(resp);
          })
          .catch(e => {
            this.displayErrorInformation(e);
            log.error(errorMsg);
            reject(new Error(errorMsg));
          });
      }
    });
  }

  public async syndicationScheduleAction(
    id: string,
    actionMode: any = syndicationScheduleActions,
  ): Promise<SyndicationSchedule[]> {
    return new Promise((resolve, reject) => {
      this.acceo
        .patch(SyndicationSchedule)(`${SYNDICATION_SCHEDULE_URL}${id}/action`, {mode: actionMode})
        .then((resp: SyndicationSchedule) => {
          this.syndicationSchedules = this.syndicationSchedules.map(schedule => (schedule.id === id ? resp : schedule));
          resolve(this.syndicationSchedules);
        })
        .catch(er => {
          if (er.message) {
            log.error(`${this.patchErrorMessage}:`, er.message);
            reject(new Error(er.message));
          } else {
            const errorMsg = 'Error occurred, Please try again.';
            log.error(errorMsg);
            reject(new Error(errorMsg));
          }
        });
    });
  }

  public async deleteSchedule(id) {
    let shouldRemove = true;

    try {
      await this.acceo.delete()(`${SYNDICATION_SCHEDULE_URL}${id}/`);
    } catch (e) {
      if (e.status_code === 404) {
        // this will go to the finally and remove it from the away since it has
        // already been deleted!
        return;
      }

      shouldRemove = false;
      log.error(e);
      this.notification.error('Could not delete Target. Please try again.');
      throw Error(e.message);
    } finally {
      if (shouldRemove) {
        _.remove(this.syndicationSchedules, {id});
      }
    }
  }

  /**
   * Get Rays for schedule with schedule.id by default
   * We can use Channel/Live Event ID instead, and in this case we need to pass
   *  content_type [SyndicationScheduleContentType = c | e]
   *  and also for this case of Channel/Live Event ID we can pass the target protocol
   *  [SyndicationTargetProtocolType = rtmp | rtmps | hls...]
   * @param id
   * @param content_type
   * @param target_protocol
   */
  public getSyndicationScheduleRay(
    id: string,
    content_type: string = '',
    target_protocol: string = '',
  ): Promise<SyndicationRayDetail> {
    return new Promise((resolve, reject) => {
      let ray_url = `${SYNDICATION_RAY_URL}${id}`;
      let paramsSeparator = '?';
      if (content_type) {
        ray_url += `${paramsSeparator}content_type=${content_type}`;
        paramsSeparator = '&';
      }
      if (target_protocol) {
        ray_url += `${paramsSeparator}target_protocol=${target_protocol}`;
      }
      this.acceo
        .get(SyndicationRayDetail)(ray_url)
        .then((resp: SyndicationRayDetail) => {
          resolve(resp);
        })
        .catch(er => {
          let msg = 'Error getting Ray Detail';
          if (er.message) {
            this.notification.error(er.message);
            msg = er.message;
          }
          log.error(`${msg}:`, er);
          reject(new Error(msg));
        });
    });
  }

  public getSyndicationRegions(target_protocol: string = ''): Promise<SyndicationRegion[]> {
    return new Promise((resolve, reject) => {
      let regionsUrl = `${SYNDICATION_REGION_URL}`;
      if (target_protocol) {
        regionsUrl += `?target_protocol=${target_protocol}`;
      }
      this.acceo
        .get(SyndicationRegionCollection)(regionsUrl)
        .then((resp: SyndicationRegionCollection) => {
          this.syndicationRegions = resp.items;
          this.syndicationRegions.unshift({value: '', text: 'Auto select'});
          resolve(this.syndicationRegions);
        })
        .catch(er => {
          let msg = 'Error getting Regions';
          if (er.message) {
            this.notification.error(er.message);
            msg = er.message;
          }
          log.error(`${msg}:`, er);
          reject(new Error(msg));
        });
    });
  }

  public displayErrorInformation(errorInfo) {
    if (errorInfo.details !== undefined && errorInfo.details.length) {
      _.forEach(errorInfo.details, detail => {
        this.notification.error(detail);
      });
    } else if (errorInfo.description !== undefined) {
      this.notification.error(errorInfo.description);
    } else if (errorInfo.message !== undefined) {
      this.notification.error(errorInfo.message);
    }
  }
}
